<template>
  <div id="app">
    <main>
      <TopHeader/>
      <Navigation v-if="isLoaded" :categories="categories"/>
      <router-view v-if="isLoaded" :categories="categories"/>
      <Loader v-if="!isLoaded"/>
    </main>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader'
import Navigation from '@/components/Navigation'
import Loader from '@/components/Loader'

export default {
  name: 'home',
  components: {
    TopHeader,
    Navigation,
    Loader
  },
  data() {
    return {
      isLoaded: false,
      categories: []
    }
  },
  methods: {
    getCategories() {
      this.$http.get(`/categories?per_page=100`)
          .then(({data}) => {
            if (data.length) {
              const excluded = ['bez-kategorii', 'featured'];
              data.filter(c => !excluded.includes(c.slug)).forEach(category => {
                this.categories.push({
                  id: category.id,
                  title: category.name.replace('&amp;', '&'),
                  slug: category.slug,
                  url: category.link,
                  count: category.count,
                  // text: `${category.slug} - ${category.count}`
                  text: category.slug
                })
              })
            }
            this.isLoaded = true
          })
          .catch((err) => {
            console.error(err);
          })
    }
  },
  created() {
    this.getCategories()
  }
}

</script>

<style lang="scss">
@import "~bootstrap-scss";
@import "~@/assets/scss/vars";
@import "~@/assets/scss/style";
</style>
