<template>
  <main class="container" :class="[currentView, {'featured': isFeatured}]" ref="container">
    <div class="wrapper" v-masonry transition-duration="0" item-selector=".item" :gutter="0" percent-position="true"
         origin-left="true">
      <div v-masonry-tile class="photo-wrapper item" v-for="(photo, index) in photos" :key="photo.id">
        <div class="photo-content preview-img-item" @click="$photoswipe.open(index, photos)">
          <img v-lazy="photo.src" alt="" :style="setThumbnailSizes(photo)">
        </div>
      </div>
    </div>
    <infinite-loading @infinite="getPhotos" ref="infiniteLoading">
      <div slot="spinner"></div>
    </infinite-loading>
    <Loader v-if="isLoading"/>
    <ScrollToTop/>
  </main>
</template>

<script>
import Events from "@/utils/events"
import InfiniteLoading from 'vue-infinite-loading'
import Loader from '@/components/Loader'
import ScrollToTop from '@/components/ScrollToTop'

export default {
  name: 'photos',
  components: {
    InfiniteLoading,
    Loader,
    ScrollToTop
  },
  props: {
    categories: {
      type: Array
    },
    category: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      windowWidth: 0,
      grid: {
        gutter: 25,
        columns: 3
      },
      photos: [],
      currentPage: 1,
      currentCategory: null,
      currentView: 'grid'
    }
  },
  watch: {
    $route() {
      this.clearPhotos()
      this.getCurrentCategory()
      this.isPages()
      if (this.isFeatured) {
        this.setFeaturedView()
      }
      setTimeout(() => {
        this.getPhotos()
        this.toggleMasonry()
      }, 50)
    }
  },
  computed: {
    isFeatured() {
      return this.$route.name === 'Featured';
    },
    isFromOldest() {
      return this.$route.name === 'Oldest';
    },
  },
  methods: {
    setFeaturedView() {
      Events.$emit('TOGGLE_VIEW', 'list');
    },
    clearPhotos() {
      this.currentPage = 1;
      this.currentCategory = null;
      this.photos = [];
      this.currentView = 'grid';
      Events.$emit('TOGGLE_VIEW', this.currentView);
    },
    setThumbnailSizes(photo) {
      const ratio = (photo.h / photo.w);
      const itemWidth = (this.$refs.container.clientWidth / this.grid.columns - this.grid.gutter);
      return {
        height: ratio * itemWidth + 'px'
      }
    },
    getPhotos($state) {
      let params = `per_page=10&page=${this.currentPage}`
      if (this.currentCategory) {
        params += `&categories=${this.currentCategory.id}`
      }
      if (!this.isFeatured) {
        params += `&categories_exclude=2`
      }
      if(this.isFromOldest) {
        params += `&order=asc`
      }
      this.isLoading = true
      this.$http.get(`/posts?${params}`)
          .then(({data}) => {
            if (data.length) {
              data.forEach(post => {
                if (post.photos !== null) {
                  post.photos.forEach(image => {
                    this.photos.push({
                      id: image.id,
                      src: image.src,
                      thumbnail: image.thumbSrc,
                      thumbnailHeight: image.thumbHeight,
                      thumbnailWidth: image.thumbWidth,
                      w: image.width,
                      h: image.height,
                      class: image.class
                    })
                  })
                } else {
                  console.log(post.slug)
                }
              })
              if ($state) {
                $state.loaded()
              }
              this.currentPage += 1
            } else {
              if ($state) {
                $state.complete()
              }
            }
            this.isLoading = false
          })
          .catch((err) => {
            console.error(err.error);
            this.isLoading = false
            $state.complete()
          });
    },
    getCurrentCategory() {
      let current = null
      if (this.category) {
        current = this.$route.params.category
      }
      if (this.isFeatured) {
        current = 'featured'
      }
      if (current) {
        this.currentCategory = this.categories.find(item => item.slug === current)
      }
    },
    toggleMasonry() {
      if (this.currentView === 'list') {
        this.$destroyVueMasonry()
      } else {
        this.$initVueMasonry()
        setTimeout(() => {
          this.$redrawVueMasonry()
        }, 50);
      }
    },
    isPages() {
      const page = this.$route.params.page
      if (page) {
        this.currentPage = parseInt(page);
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth >= 768) {
        this.grid.columns = 3
        this.grid.gutter = 25
      } else {
        this.grid.columns = 2
        this.grid.gutter = 10
      }
    }
  },
  mounted() {
    this.getCurrentCategory();
    this.isPages();
    if (this.isFeatured) {
      this.setFeaturedView();
    }
    Events.$on('TOGGLE_VIEW', (type) => {
      this.currentView = type
      this.toggleMasonry()
    });
    Events.$on('FETCH_PHOTOS', () => {
      if (!this.isLoading) {
        this.getPhotos();
      }
    });
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.onResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 20px;
  @media only screen and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.grid {
  .wrapper {
    margin: 0 -12.5px;
  }

  .photo-wrapper {
    float: left;
    width: 33.33%;
    margin-bottom: 25px;
    cursor: pointer;
    transition-property: opacity, transform;
    padding: 0 calc(25px / 2);

    .photo-content {
      position: relative;
      background-color: #f5f5f5;

      img {
        opacity: 0;
        transition: opacity 0.25s ease-in-out;

        &[lazy=loaded] {
          opacity: 1;
          transition: opacity 0.25s ease-in-out;
          height: auto !important;
        }
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
      }

      &:hover {
        position: relative;

        &:after {
          opacity: 0.13;
        }
      }
    }
  }

  .section-header {
    display: none;
  }

  &.featured-container {
    img {
      height: auto !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .wrapper {
      margin: 0 -5px;
    }
    .photo-wrapper {
      width: 50%;
      padding: 0 5px;
      margin-bottom: 10px;
    }
  }
}

.list {
  padding: 0;

  & > div {
    margin: 0 auto;
    max-width: 1280px;
  }

  .photo-wrapper {
    float: left;
    width: 100%;
    position: relative;
    background-color: #f5f5f5;
    margin-bottom: 200px;

    .photo-content {
      position: relative;
      background-color: #f5f5f5;

      img {
        opacity: 0;
        transition: opacity 0.25s ease-in-out;

        &[lazy=loaded] {
          opacity: 1;
          transition: opacity 0.25s ease-in-out;
          height: auto !important;
        }
      }
    }

    @media only screen and (max-width: 1000px) {
      height: auto !important;
      margin-bottom: 28px;
    }

    img {
      height: auto !important;
    }
  }
}
</style>
