<template>
  <header>
    <div class="container">
      <div class="row">
        <div class="hero col-sm-6">
          <h1 @click="goToHome">{{ title }}</h1>
          <h2 @click="goToHome">{{ name }}</h2>
        </div>
        <div class="social col-sm-6">
          <a class="button" href="mailto:kontakt@radziu.org" title="Contact">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
              <path fill="#9E9E9E"
                    d="M2.925,0 C1.31762786,0 0,1.28102713 0,2.84375 L0,11.15625 C0,12.7189729 1.31762786,14 2.925,14 L15.075,14 C16.6823721,14 18,12.7189729 18,11.15625 L18,2.84375 C18,1.28102713 16.6823721,0 15.075,0 L2.925,0 Z M2.925,1.3125 L15.075,1.3125 C15.9525279,1.3125 16.65,1.99059787 16.65,2.84375 L16.65,3.32739269 L9,7.34777838 L1.35,3.32739269 L1.35,2.84375 C1.35,1.99059787 2.04747214,1.3125 2.925,1.3125 Z M1.35,4.81933594 L8.67919905,8.6713865 C8.8793996,8.77651014 9.1206004,8.77651014 9.32080095,8.6713865 L16.65,4.81933594 L16.65,11.15625 C16.65,12.0094021 15.9525279,12.6875 15.075,12.6875 L2.925,12.6875 C2.04747214,12.6875 1.35,12.0094021 1.35,11.15625 L1.35,4.81933594 Z"/>
            </svg>
          </a>
          <a class="button" href="https://www.instagram.com/radosky_/" target="_blank" title="Instagram">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path fill="#9E9E9E"
                    d="M4.70454545,0 C2.12215927,0 0,2.11931855 0,4.70454545 L0,11.2954545 C0,13.8778407 2.11931855,16 4.70454545,16 L11.2954545,16 C13.8778407,16 16,13.8806822 16,11.2954545 L16,4.70454545 C16,2.12215927 13.8806822,0 11.2954545,0 L4.70454545,0 Z M4.70454545,1.45454545 L11.2954545,1.45454545 C13.0937498,1.45454545 14.5454545,2.90625018 14.5454545,4.70454545 L14.5454545,11.2954545 C14.5454545,13.0937498 13.0937498,14.5454545 11.2954545,14.5454545 L4.70454545,14.5454545 C2.90625018,14.5454545 1.45454545,13.0937498 1.45454545,11.2954545 L1.45454545,4.70454545 C1.45454545,2.90625018 2.90625018,1.45454545 4.70454545,1.45454545 Z M12.2954545,3.04545455 C11.9289775,3.04545455 11.6363636,3.33806836 11.6363636,3.70454545 C11.6363636,4.07102255 11.9289775,4.36363636 12.2954545,4.36363636 C12.6619316,4.36363636 12.9545455,4.07102255 12.9545455,3.70454545 C12.9545455,3.33806836 12.6619316,3.04545455 12.2954545,3.04545455 Z M8,3.63636364 C5.599432,3.63636364 3.63636364,5.599432 3.63636364,8 C3.63636364,10.400568 5.599432,12.3636364 8,12.3636364 C10.400568,12.3636364 12.3636364,10.400568 12.3636364,8 C12.3636364,5.599432 10.400568,3.63636364 8,3.63636364 Z M8,5.09090909 C9.61647709,5.09090909 10.9090909,6.38352291 10.9090909,8 C10.9090909,9.61647709 9.61647709,10.9090909 8,10.9090909 C6.38352291,10.9090909 5.09090909,9.61647709 5.09090909,8 C5.09090909,6.38352291 6.38352291,5.09090909 8,5.09090909 Z"/>
            </svg>
          </a>
          <a class="button" href="https://www.youtube.com/channel/UCTu6oReXxWqp3rnaDlyDRAA" target="_blank"
             title="Youtube">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
              <path fill="#9E9E9E"
                    d="M17.497553,2.40625 C17.331158,1.44409169 16.5383361,0.743408312 15.6182708,0.524658312 C14.2414356,0.21875 11.6933114,0 8.93637857,0 C6.1810765,0 3.59216982,0.21875 2.21370297,0.524658312 C1.29526936,0.743408312 0.500815759,1.39965831 0.334420773,2.40625 C0.166394987,3.5 0,5.03125 0,7 C0,8.96875 0.166394987,10.5 0.37520412,11.59375 C0.543229906,12.5559083 1.33605229,13.2565917 2.25448632,13.4753417 C3.71615025,13.78125 6.22185984,14 8.97879272,14 C11.735726,14 14.2414356,13.78125 15.7030995,13.4753417 C16.6215331,13.2565917 17.4143555,12.6003417 17.5823817,11.59375 C17.7487763,10.5 17.9575854,8.92431662 18,7 C17.9151713,5.03125 17.7063622,3.5 17.497553,2.40625 Z M6.68189226,10.0625 L6.68189226,3.9375 L11.7765089,7 L6.68189226,10.0625 Z"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Events from "@/utils/events"

export default {
  name: 'TopHeader',
  data() {
    return {
      title: 'Phots.',
      name: 'Radek Skrzypczak'
    }
  },
  methods: {
    goToHome() {
      Events.$emit('GO_HOME');
      this.$router.push({name: 'Photos'}).catch(() => {
      });
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  margin: 76px 0 71px;
}

h1 {
  font-family: "Yellowtail", cursive;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d4d4d;
  margin: 0;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    transition: color 0.3s ease;

    &:hover {
      color: #4d4d4d;
    }
  }

}

h2 {
  font-family: "Manrope", sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: -0.2px;
  color: #050505;
  margin: -4px 0 0 0;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    transition: color 0.3s ease;

    &:hover {
      color: #4d4d4d;
    }
  }
}

.social {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  margin-right: 3px;
  margin-bottom: 6px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    path {
      transition: all 0.3s ease;
    }
  }

  @media screen and (min-width: 768px) {
    &:hover {
      background-color: #f8f8f8;

      svg {
        path {
          fill: #050505;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    margin: 0;
  }

  .hero {
    h1 {
      position: absolute;
      top: 11px;
      left: 16px;
    }

    h2 {
      margin: 70px 0 35px;
      text-align: center;
    }
  }

  .social {
    position: absolute;
    top: 5px;
    right: 4px;
    padding: 0;
  }

  .button {
    margin: 0;
  }
}
</style>
