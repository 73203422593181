import Masonry from 'masonry-layout'

const attributesMap = {
  'column-width': 'columnWidth',
  'transition-duration': 'transitionDuration',
  'item-selector': 'itemSelector',
  'origin-left': 'originLeft',
  'origin-top': 'originTop',
  'fit-width': 'fitWidth',
  'stamp': 'stamp',
  'gutter': 'gutter',
  'percent-position': 'percentPosition',
  'horizontal-order': 'horizontalOrder',
  'stagger': 'stagger'
}
const EVENT_ADD = 'vuemasonry.itemAdded'
const EVENT_REMOVE = 'vuemasonry.itemRemoved'
const EVENT_DESTROY = 'vuemasonry.destroy'
const EVENT_REINIT = 'vuemasonry.reinit'

const stringToBool = function (val) { return (val + '').toLowerCase() === 'true' }

const numberOrSelector = function (val) { return isNaN(val) ? val : parseInt(val) }

const collectOptions = function (attrs) {
  const res = {}
  const attributesArray = Array.prototype.slice.call(attrs)
  attributesArray.forEach(function (attr) {
    if (Object.keys(attributesMap).indexOf(attr.name) > -1) {
      if (attr.name.indexOf('origin') > -1) {
        res[attributesMap[attr.name]] = stringToBool(attr.value)
      } else if (attr.name === 'column-width' || attr.name === 'gutter') {
        res[attributesMap[attr.name]] = numberOrSelector(attr.value)
      } else {
        res[attributesMap[attr.name]] = attr.value
      }
    }
  })
  return res
}

export const VueMasonryPlugin = function () {}

VueMasonryPlugin.install = function (Vue, options) {
  const Events = new Vue({})
  const masonryId = 'VueMasonry'

  Vue.directive('masonry', {
    props: ['transitionDuration', ' itemSelector'],

    inserted: function (el, binding) {
      if (!Masonry) {
        throw new Error('Masonry plugin is not defined. Please check it\'s connected and parsed correctly.')
      }

      let masonry = new Masonry(el, collectOptions(el.attributes))
      let masonryDraw = function () {
        if(masonry) {
          masonry.reloadItems()
          masonry.layout()
        }
      }

      const masonryReinit = function (eventData) {
        masonry = new Masonry(el, collectOptions(el.attributes))
        masonryDraw = function () {
          if(masonry) {
            masonry.reloadItems()
            masonry.layout()
          }
        }
        Events.$on(`${EVENT_ADD}__${masonryId}`, masonryDraw)
        Events.$on(`${EVENT_REMOVE}__${masonryId}`, masonryDraw)
        Events.$on(`${EVENT_DESTROY}__${masonryId}`, masonryDestroy)
      }


      const masonryDestroy = function (eventData) {
        if(masonry) {
          Events.$off(`${EVENT_ADD}__${masonryId}`, masonryDraw)
          Events.$off(`${EVENT_REMOVE}__${masonryId}`, masonryDraw)
          Events.$off(`${EVENT_DESTROY}__${masonryId}`, masonryDestroy)
          masonry.destroy()
          masonry = null
        }
      }

      Events.$on(`${EVENT_ADD}__${masonryId}`, masonryDraw)
      Events.$on(`${EVENT_REMOVE}__${masonryId}`, masonryDraw)
      Events.$on(`${EVENT_DESTROY}__${masonryId}`, masonryDestroy)
      Events.$on(`${EVENT_REINIT}__${masonryId}`, masonryReinit)
    },
    unbind: function (el, binding) {
      Events.$emit(`${EVENT_DESTROY}__${masonryId}`)
    }
  })

  Vue.directive('masonryTile', {

    inserted: function (el, binding) {
      Events.$emit(`${EVENT_ADD}__${masonryId}`, {
        'element': el
      })
    },
    unbind: function (el, binding) {
      Events.$emit(`${EVENT_REMOVE}__${masonryId}`, {
        'element': el
      })
    }
  })

  Vue.prototype.$redrawVueMasonry = function (id) {
    Events.$emit(`${EVENT_ADD}__${masonryId}`)
  }
  Vue.prototype.$destroyVueMasonry = function (id, e) {
    Events.$emit(`${EVENT_DESTROY}__${masonryId}`)
  }
  Vue.prototype.$initVueMasonry = function (id, e) {
    Events.$emit(`${EVENT_REINIT}__${masonryId}`)
  }
}