import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAxios from 'vue-axios'
import axios from 'axios'
import {VueMasonryPlugin} from './plugins/masonry'
import VueLazyload from 'vue-lazyload'
import VuePhotoSwipe from './plugins/photoswipe'


Vue.config.productionTip = false
const axiosSettings = axios.create({
    baseURL: 'https://phots.pl/wp-json/wp/v2/',
    headers: {
        'Content-Type': 'application/json'
    }
})

Vue.use(VueAxios, axiosSettings)
Vue.use(VueMasonryPlugin)
Vue.use(VuePhotoSwipe)
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'dist/error.png',
    loading: '',
    attempt: 1
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
