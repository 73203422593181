<template>
  <div class="scroll-top-button" @click="scrollToTop" :class="{ visible: isVisible }">
    <svg width="20px" height="10px" viewBox="0 0 20 10" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
            d="M10,7.62678918 L1.92897097,0.284922942 C1.48788067,-0.0949743141 0.771908017,-0.0949743141 0.330817722,0.284922942 C-0.110272574,0.664820199 -0.110272574,1.28146502 0.330817722,1.66136228 L9.2467371,9.73090611 C9.66332238,10.089698 10.3377431,10.089698 10.7532629,9.73090611 L19.6691823,1.66136228 C20.1102726,1.28146502 20.1102726,0.664820199 19.6691823,0.284922942 C19.228092,-0.0949743141 18.5121193,-0.0949743141 18.071029,0.284922942 L10,7.62678918 Z"
            fill="#303030"
            transform="translate(10.000000, 5.000000) scale(1, -1) rotate(-360.000000) translate(-10.000000, -5.000000) "></path>
      </g>
    </svg>
    <span>To up</span>
  </div>
</template>

<script>

export default {
  name: 'ScrollToTop',
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      this.isVisible = (currentScrollPosition > 100)
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
.scroll-top-button {
  position: fixed;
  z-index: 999;
  right: 100px;
  bottom: 100px;
  width: 48px;
  height: 48px;
  padding: 9px 0;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  visibility: hidden;

  svg {
    display: block;
    margin: 0 auto;

    path {
      transition: all 0.25s ease-in-out;
    }
  }

  span {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #3d3d3d;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    text-indent: 1px;
    transition: all 0.25s ease-in-out;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    span {
      color: #c8c8c8;
    }

    svg path {
      fill: #c8c8c8;
    }
  }

  @media only screen and (max-width: 1024px) {
    display: none !important;
  }
}
</style>
