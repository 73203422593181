<template>
  <nav class="main-nav">
    <div class="container">
      <ul class="menu">
        <li v-if="!isFeaturedPage">
          <div class="category">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5">
              <path fill="#050505" fill-rule="evenodd"
                    d="M2.58337357,4.21971753 C2.27664467,4.5327485 2.27664468,5.03362161 2.5833736,5.34665257 L5.9643051,8.79704855 C6.25420504,9.09290476 6.72905423,9.09773327 7.02491044,8.80783333 C7.02854184,8.80427504 7.03213696,8.80067992 7.03569525,8.79704852 L10.4166264,5.34665259 C10.7233553,5.03362162 10.7233553,4.5327485 10.4166264,4.21971753 C10.117836,3.9147881 9.62842428,3.90981149 9.32349485,4.20860196 C9.31975208,4.21226938 9.3160467,4.21597476 9.31237928,4.21971753 L6.50000018,7.08987988 L6.50000018,7.08987988 L3.68762076,4.2197175 C3.38883025,3.91478807 2.89941855,3.90981148 2.59448912,4.20860198 C2.59074636,4.2122694 2.58704099,4.21597477 2.58337357,4.21971753 Z"
                    transform="translate(-2 -4)"/>
            </svg>
            <select class="btn" @change="onCategoryChange" v-model="selectedCategory"
                    :style="{ width: this.selectWidth + 'px' }">
              <option v-for="category in categories" :value="category.slug" :key="category.id">{{
                  category.text
                }}
              </option>
            </select>
          </div>
        </li>
        <li v-else>
          <router-link class="btn" :to="{ name: 'Photos' }">All</router-link>
        </li>
        <li>
          <div class="category">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5">
              <path fill="#050505" fill-rule="evenodd"
                    d="M2.58337357,4.21971753 C2.27664467,4.5327485 2.27664468,5.03362161 2.5833736,5.34665257 L5.9643051,8.79704855 C6.25420504,9.09290476 6.72905423,9.09773327 7.02491044,8.80783333 C7.02854184,8.80427504 7.03213696,8.80067992 7.03569525,8.79704852 L10.4166264,5.34665259 C10.7233553,5.03362162 10.7233553,4.5327485 10.4166264,4.21971753 C10.117836,3.9147881 9.62842428,3.90981149 9.32349485,4.20860196 C9.31975208,4.21226938 9.3160467,4.21597476 9.31237928,4.21971753 L6.50000018,7.08987988 L6.50000018,7.08987988 L3.68762076,4.2197175 C3.38883025,3.91478807 2.89941855,3.90981148 2.59448912,4.20860198 C2.59074636,4.2122694 2.58704099,4.21597477 2.58337357,4.21971753 Z"
                    transform="translate(-2 -4)"/>
            </svg>
            <select class="btn" @change="onViewChange" v-model="currentView">
              <option value="grid">Grid</option>
              <option value="list">List</option>
            </select>
          </div>
        </li>
        <li>
          <span>&copy; {{ new Date().getFullYear() }}</span>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
const GRID_VIEW = 'grid';
const ALL_CATEGORY = 'all';

import Events from "@/utils/events"

export default {
  name: 'Navigation',
  props: {
    categories: {
      type: Array
    }
  },
  data() {
    return {
      currentView: GRID_VIEW,
      selectedCategory: null,
      selectWidth: null,
    }
  },
  computed: {
    isFeaturedPage() {
      return this.$route.name === 'Featured';
    }
  },
  methods: {
    onViewChange() {
      Events.$emit('TOGGLE_VIEW', this.currentView);
    },
    selectAutoWidth() {
      let selected = this.categories.find(item => item.slug === this.selectedCategory);
      const testElement = document.createElement('span');
      testElement.classList.add('btn-test')
      testElement.innerText = selected.text.toLocaleUpperCase();
      document.body.appendChild(testElement);
      this.selectWidth = testElement.offsetWidth + 20;
      testElement.remove();
    },
    onCategoryChange() {
      let selected = this.categories.find(item => item.slug === this.selectedCategory);
      this.selectAutoWidth()
      if (selected.slug === ALL_CATEGORY) {
        this.$router.push({name: 'Photos'});
      } else {
        this.$router.push({
          name: 'Category', params: {
            category: selected.slug
          }
        });
      }
    },
    resetSelectedCategory() {
      this.selectedCategory = ALL_CATEGORY;
    },
    initialCategory() {
      if (this.$route.params.category !== undefined) {
        this.selectedCategory = this.$route.params.category;
      } else {
        this.selectedCategory = ALL_CATEGORY;
      }
    },
  },
  created() {
    this.initialCategory();
    this.selectAutoWidth();
    Events.$on('TOGGLE_VIEW', (type) => {
      this.currentView = type;
    });
    Events.$on('GO_HOME', () => {
      this.selectedCategory = ALL_CATEGORY;
    });
  }
}
</script>

<style lang="scss" scoped>
.btn {
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  transition: all 0.3s ease;
  color: #050505;
  text-transform: uppercase;
  padding: 0;
  text-align: left;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: #4d4d4d;
  }
}

.main-nav {
  margin-bottom: 16px;
  animation: fade 0.5s ease-in-out both;
}

.menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin-left: 25px;

    &:first-child {
      margin-left: 0;
    }
  }

  span {
    font-family: "Manrope", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #707070;
  }
}

.category {
  position: relative;

  svg {
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 4px;

    path {
      transition: all 0.3s ease;
    }
  }

  &:hover {
    svg {
      path {
        fill: #4d4d4d;
      }
    }
  }

  select {
    position: relative;
    z-index: 9;
    appearance: none;
    width: 50px;

    &:focus {
      box-shadow: none;
    }

    option {
      background-color: #fff;
    }
  }
}

</style>
